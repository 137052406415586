
@use '../utils' as *;

/*----------------------------------------*/
/*  26. News css start
/*----------------------------------------*/

// News style
.bd-news {
	&__content {
		padding-right: 1px;
	}
	&__meta {
		&-list {
			@include flexbox();
			align-items: center;
			gap: 20px;
			margin-bottom: 10px;
		}
		&-item {
			span {
				a {
					font-size: 14px;
					text-transform: uppercase;
					font-weight: var(--bd-fw-medium);
					color: var(--clr-common-body-text);
					&:hover {
						color: var(--clr-theme-1);
					}
				}
			}
			i {
				margin-right: 10px;
			}
		}
	}
	&__btn {
		text-transform: uppercase;
		color: var(--clr-common-body-text);
		font-size: 14px;
		font-weight: var(--bd-fw-medium);
		i {
			transform: rotate(130deg);
			margin-left: 5px;
			@include transition(.3s);
		}
	  &:hover {
		color: var(--clr-theme-1); 
	  }
	}
	&__thumb {
		margin-bottom: 20px;
		overflow: hidden;
		@include border-radius(5px);
	}
	&__item {
		&:hover {
			.bd-news__thumb {
				img {
					transform: scale(1.05);
				}
			}
		}
	}
	&__title {
		h3 {
			margin-bottom: 20px;
			@media #{$lg}{
				font-size: 20px;
			  }
			a {
				&:hover {
					color: var(--clr-theme-1);
				}
			}
		}
	}
	&__btn {
		span {
			position: relative;
			overflow: hidden;
			i {
				&:first-child {
					position: absolute;
					top: 11px;
					left: -7px;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
		&:hover {
			span {
				i {
					&:first-child {
						opacity: 1;
						visibility: visible;
						top: 2px;
						left: 0;
					}
					&:last-child {
						opacity: 0;
						visibility: hidden;
						transform: translateX(7px) translateY(-11px) rotate(130deg);
					}
				}
			}
		}
	}
}
.blog {
	&-wrapper {
		@include border-radius(5px);
	}
	&-content {
		p {
			margin-bottom: 30px;
		}
		&-wrapper {
			padding: 30px 30px 32px 30px;
			background: var(--clr-common-white);
			box-shadow: 0px 20px 40px rgba(28, 51, 84, 0.1);
			border-radius: 0 0 5px 5px;
		}
	}
	&-meta {
		@include flexbox();
		align-items: center;
		margin-bottom: 10px;
		flex-wrap: wrap;
		gap: 0px 25px;
		& > div {
			@include flexbox();
			align-items: center;
			@include flexbox();;
		}
		i {
			font-size: 14px;
			margin-right: 5px;
			line-height: 1;
		}
		span {
			font-size: 14px;
		}
	}
	&-thumb {
		@include border-radius(5px);
		overflow: hidden;
		position: relative;
		.play-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			z-index: 1;
		}
		img {
			@include transition(3s);
			width: 100%;
			zoom: 1;
			filter: alpha(opacity=100);
			opacity: 1;
			-webkit-transition: all 0.9s ease 0s;
			-moz-transition: all 0.9s ease 0s;
			-ms-transition: all 0.9s ease 0s;
			-o-transition: all 0.9s ease 0s;
			transition: all 0.9s ease 0s;
		}
		&:hover {
			img {
				-webkit-transform: rotate(-2deg) scale(1.1);
				-moz-transform: rotate(-2deg) scale(1.1);
				-ms-transform: rotate(-2deg) scale(1.1);
				-o-transform: rotate(-2deg) scale(1.1);
				transform: rotate(-2deg) scale(1.1);
			}
		}
	}
	&-main {
		&-wrapper {
			.blog-content {
				text-align: left;
				padding: 0;
				h3 {
					font-size: 36px;
					margin-bottom: 20px;
					a {
						&:hover {
							color: var(--clr-theme-1);
						}
					}
				}
			}
		}
	}
}
.blog__details {
	&__tag span {
		color: var(--clr-common-heading);
		font-weight: 600;
		margin-right: 10px;
	}
}
div.tagcloud a {
	display: inline-block;
	height: 36px;
	line-height: 36px;
	padding: 0 15px;
	font-size: 15px;
	font-weight: 500;
	background: var(--clr-bg-grey);
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	margin-right: 8px;
	margin-bottom: 8px;
	&:hover {
		color: var(--clr-common-white);
		background: var(--clr-theme-1);
	}
}
.pulse-btn {
	display: inline-block;
	width: 80px;
	height: 80px;
	line-height: 80px;
	text-align: center;
	background-color: var(--clr-common-white);
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;
	color: var(--clr-common-black);
	animation: pulse 2s infinite;
	cursor: pointer;
	&:hover {
		background: var(--clr-theme-1);
		color: var(--clr-common-white);
	}
}
.sidebar {
	&__search {
		input {
			width: 100%;
			height: 60px;
			line-height: 56px;
			background: var(--clr-common-white);
			border: 1px solid var(--clr-border-17);
			box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			padding-left: 30px;
			padding-right: 45px;
			outline: 0;
		}
		button {
			position: absolute;
			top: 50%;
			right: 20px;
			left: auto;
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
	&__widget {
		padding: 30px 30px;
		@include border-radius(5px);
		border: 1px solid var(--clr-border-17);
		padding: 30px 30px;
		@include border-radius(5px);
		box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
		background-color: var(--clr-common-white);
		.sidebar__tag {
			@include flexbox();
			flex-wrap: wrap;
			gap: 8px;
		}
	}
	&__category {
		ul {
			li {
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				a {
					font-size: 16px;
					font-weight: 500;
					color: #575757;
					padding-left: 20px;
					position: relative;
					&::after {
						position: absolute;
						content: "";
						left: 0;
						top: 9px;
						width: 6px;
						height: 6px;
						background: #adaeba;
						-webkit-border-radius: 50%;
						-moz-border-radius: 50%;
						border-radius: 50%;
					}
					&:hover {
						color: var(--clr-theme-1);
						&::after {
							background: var(--clr-theme-1);
						}
					}
				}
			}
		}
	}
	&__tag {
		a {
			display: inline-block;
			height: 32px;
			line-height: 30px;
			padding: 0 15px;
			font-size: 15px;
			font-weight: 500;
			background: var(--clr-bg-grey);
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			&:hover {
				color: var(--clr-common-white);
				background: var(--clr-theme-1);
			}
		}
	}
}
.rc {
	&__post {
		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
	&__meta {
		span {
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
			display: inline-block;
			margin-bottom: 10px;
		}
	}
	&__thumb {
		img {
			width: 75px;
			height: 75px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			object-fit: cover;
		}
	}
	&__title {
		font-size: 18px;
		color: #141517;
		margin-bottom: 0px;
		font-weight: 600;
		line-height: 1.1;
		a {
			&:hover {
				color: var(--clr-theme-1);
			}
		}
	}
}
blockquote {
	background: var(--clr-bg-grey);
	padding: 35px 50px;
	margin-bottom: 35px;
	@include border-radius(5px);
	&::before {
		content: "";
		position: static;
		font-family: "Font Awesome 5 Pro";
		font-size: 32px;
		color: var(--clr-theme-1);
		line-height: 1;
		margin-bottom: 18px;
		display: inline-block;
		font-weight: var(--bd-fw-bold);
	}
	p {
		line-height: 1.5;
		font-size: 20px;
		color: #57565e;
		margin-bottom: 0;
	}
	cite {
		font-size: 18px;
		display: block;
		margin-top: 10px;
		color: var(--clr-common-heading);
		font-style: inherit;
		font-weight: 600;
		position: relative;
		&::before {
			content: "";
			font-size: 28px;
			color: var(--clr-theme-1);
			padding-bottom: 0px;
			display: inline-block;
			background: var(--clr-theme-1);
			padding-bottom: 0px;
			height: 2px;
			width: 40px;
			font-weight: 400;
			text-align: center;
			top: -4px;
			margin-right: 10px;
			position: relative;
		}
	}
}
.bd-sidebar {
	&__author-img {
		margin-bottom: 20px;
	}
	&__social {
		a {
			margin-right: 15px;
			&:hover {
				color: var(--clr-theme-1);
			}
		}
	}
}
.sidebar-widget-wrapper {
	position: sticky;
	top: 90px;
}
// Postbox-coment
.bd-postbox {
	ul {
		li {
			&.children {
				padding-left: 130px;
			}
		}
	}
	&__comment {
		&-box {
			border-bottom: 1px solid #f3f3f3;
			margin-bottom: 30px;
			padding-bottom: 5px;
			border-bottom: 1px solid #f3f3f3;
			margin-bottom: 30px;
			padding-bottom: 5px;
		}
		&-info {
			margin-top: 15px;
		}
		&-name {
			margin-bottom: 18px;
			h4 {
				font-size: 18px;
			}
			span {
				font-size: 14px;
			}
		}
		&-reply {
			a {
				display: inline-block;
				background: transparent;
				height: 35px;
				line-height: 35px;
				padding: 0 20px;
				font-size: 14px;
				border: 1px solid var(--clr-border-1);
				position: relative;
				@include border-radius(5px);
				&:hover {
					border-color: transparent;
					background-color: var(--clr-theme-1);
					color: var(--clr-common-white);
				}
			}
		}
		&-text {
			p {
				font-size: 14px;
			}
		}
		&-title {
			font-size: 26px;
			margin-bottom: 30px;
		}
	}
	&__contact {
		h3 {
			font-size: 26px;
			font-weight: 600;
			margin-bottom: 30px;
		}
	}
	&__singel {
		&-input {
			input {
				width: 100%;
				height: 60px;
				line-height: 60px;
				border: 1px solid var(--clr-border-16);
				background: transparent;
				font-size: 15px;
				outline: none;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				margin-bottom: 20px;
				padding: 0 24px;
			}
			textarea {
				width: 100%;
				height: 180px;
				line-height: 60px;
				border: 1px solid var(--clr-border-16);
				background: transparent;
				font-size: 15px;
				outline: none;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				margin-bottom: 15px;
				resize: none;
				padding: 0 25px;
			}
		}
	}
	&__check {
		label {
			padding-left: 8px;
			color: var(--clr-common-text);
			position: relative;
		}
	}
}

// check input
input.e-check-input {
	margin: 0;
	appearance: none;
	-moz-appearance: none;
	display: block;
	width: 18px;
	min-width: 18px;
	height: 18px;
	background: var(--clr-common-white);
	border: 1px solid #b9bac1;
	outline: none;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	&:checked {
		position: relative;
		background-color: var(--clr-theme-1);
		border-color: transparent;
		&::after {
			box-sizing: border-box;
			content: "\f00c";
			position: absolute;
			font-family: var(--bd-ff-fontawesome);
			font-size: 10px;
			color: var(--clr-common-white);
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}
}

[dir="rtl"]
{
	
	.bd-news {
		&__meta {
			&-item {
				i {
					margin-right: 0;
					margin-left: 10px;
				}
			}
		}
	}
	
	.blog {
		&-meta{
			i{
				
				margin-right: 0;
				margin-left: 5px;
			}
		}
		&-main {
			&-wrapper {
				.blog-content {
					text-align: right;
				}
			}
		}
	}
}