/* Terms Conditions Template - Start
================================================== */

@use "../utils" as *;
// var(--clr-common-heading);
.terms_conditions_content {
	padding: 40px;
	background-color: var(--clr-common-white);
	border-radius: 10px;

	@media #{$xs} {
		padding: 30px;
	}

	.warpper_title {
		font-size: 38px;
		line-height: 1.3;
		margin-bottom: 30px;

		@media #{$xs,$sm,$lg} {
			font-size: 34px;
		}

		@media #{$xs} {
			font-size: 26px;
		}
	}

	p {
		font-size: 17px;
		line-height: 27px;
		margin-bottom: 30px;
	}

	.info_title {
		font-size: 24px;
		margin-bottom: 20px;

		@media #{$xs} {
			font-size: 20px;
		}
	}

	.icon_list {
		margin-bottom: 40px;
	}

	.icon_list.unordered_list_block>li:not(:last-child) {
		margin-bottom: 14px;
	}

	.icon_list .list_item_text {
		color: var(--clr-common-body-text);
	}

	.icon_list .list_item_icon {
		flex: none;
		width: auto;
		height: auto;
		font-size: 5px;
		border-radius: 0;
		margin-inline-end: 10px;
		color: var(--clr-common-body-text);
		background-color: transparent;
	}

	.link {
		font-size: 17px;
		font-weight: 600;
		line-height: 27px;
		color: var(--clr-theme-1);
		text-decoration: underline;
	}

	.author_mail {
		font-size: 21px;
		font-weight: 700;
		line-height: 29px;
		color: var(--clr-common-heading);
	}
}

.nav.tabs_nav_boxed {
	padding: 30px 0;
	border-radius: 12px;
	border: 1px solid var(--clr-common-heading);
}

.tabs_nav_boxed button {
	display: flex;
	font-size: 16px;
	font-weight: 500;
	line-height: 27px;
	padding: 10px 40px;
	position: relative;
	align-items: center;
}

.tabs_nav_boxed button i {
	opacity: 0;
	font-size: 10px;
	margin-right: 10px;
	line-height: 1;
	color: var(--clr-theme-1);
	transform: translateX(-10px);
	transition: .3s cubic-bezier(.5, 1, .89, 1);
}

.tabs_nav_boxed button.active i {
	opacity: 1;
	transform: translateX(0);
	background: none;
}

.tabs_nav_boxed button.active:hover {
	color: var(--clr-theme-1);
}

/* Terms Conditions Template - End
================================================== */