@use '../utils' as *;

/*----------------------------------------*/
/*  13. Brand css start 
/*----------------------------------------*/

// Brand style css
.bd-singel {
	&__brand {
		text-align: center;
	}
}