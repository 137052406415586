@use '../utils' as *;

/*----------------------------------------*/
/*  17. Counter css start 
/*----------------------------------------*/

// Counter css style
.bd-counter {
	&__area {
		position: relative;
		margin-top: -115px;
		z-index: 5;
	}
	&__main {
		position: relative;
		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			z-index: -1;
			top: 0;
			right: -100%;
			background: var(--clr-theme-1);
			@media #{$xs,$sm,$md}{
			display: none;
			}
		}
	}
	&__content {
		text-align: center;
		span.counter {
			font-size: 60px;
			font-weight: var(--bd-fw-sbold);
			color: var(--clr-common-white);
			line-height: 1;
			margin-bottom: 20px;
			display: inline-block
		}
		h4 {
			margin-bottom: 0;
			color: var(--clr-common-white);
			font-size: 18px;
		}
	}
	&-shape-line {
		width: 25px;
		height: 4px;
		background: var(--clr-common-white);
		position: absolute;
		top: 20px;
		left: 20px;
		&:before {
			position: absolute;
			content: "";
			height: 25px;
			width: 4px;
			background: var(--clr-common-white);
			left: 0px;
		}
	}
}
// Counter-style-2
.counter-style-2 {
	.bd-counter {
		&__content {
			span {
				color: var(--clr-common-heading);
				display: inline-block;
				margin-bottom: 5px;
			}
			h4 {
				color: var(--clr-common-heading);
			}
		}
	}
}
