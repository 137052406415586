@use '../utils' as *;

/*----------------------------------------*/
/*  09. Section title css start 
/*----------------------------------------*/

// Section title style
.bd-sub {
	&__title {
		font-size: 18px;
		font-weight: 500;
		color: var(--clr-theme-1);
		display: block;
		margin-bottom: 10px;
	}
}
.bd-section {
	&__title {
		font-size: 50px;
		color: var(--clr-common-heading);
		font-weight: 700;
		line-height: 1.1;
		@media #{$xs}{
			font-size: 40px;
		}
	}
}
// Small-section-title
.bd-sm {
	&__section-title {
		h3 {
			font-size: 32px;
			margin-bottom: 0;
		}
	}
}
.bd-section {
	&__title {
		&-icon {
			svg {
				margin-right: 10px;
			}
		}
	}
}
.bd-section {
	&__arrow {
		color: var(--clr-common-heading);
		font-weight: var(--bd-fw-medium);
		a {
			&:hover {
				color: var(--clr-theme-1);
			}
		}
		i {
			margin-left: 7px;
		}
	}
}