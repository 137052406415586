
@use '../utils' as *;

/*----------------------------------------*/
/*  32. Subcribe css start
/*----------------------------------------*/

.bd-subcribe {
	&__text {
		p {
			color: var(--clr-common-heading);
		}
	}
	&__filter {
		input {
			width: 100%;
			height: 60px;
			border: 0;
			background: var(--clr-common-white);
			padding-left: 30px;
			padding-right: 200px;
			@include border-radius(30px);
			@media #{$xs}{
				padding-right: 30px;
			}
		}
		button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background: var(--clr-theme-1);
			height: 60px;
			padding: 0 40px;
			color: var(--clr-common-white);
			@include border-radius(0px 30px 30px 0px);
			@media #{$xs}{
				position: sticky;
				margin-top: 50px;
				@include border-radius(30px 30px 30px 30px);
			}
		}
	}
}
