
@use '../utils' as *;

/*----------------------------------------*/
/*  27. Pgage banner css start
/*----------------------------------------*/

// Pgage banner style
.bd-page {
	&__banner {
		&-content {
			padding: 202px 0;
			@media #{$xs,$sm}{
				padding: 180px 0;
			}
			h2 {
				font-size: 50px;
				color: var(--clr-common-white);
				font-weight: var(--bd-fw-sbold);
				margin-bottom: 5px;
				@media #{$xs}{
					font-size: 45px;
				}
			}
			span {
				font-size: 18px;
				color: var(--clr-common-white);
			}
		}
	}
}
.page-overlay {
	position: relative;
	z-index: 1;
	&::before {
		position: absolute;
		content: "";
		height: 100%;
		width: 100%;
		background: var(--clr-common-black);
		opacity: 0.65;
		z-index: -1;
	}
}
