@use '../utils' as *;

/*-----------------------------------------------------------------------------------

    Theme Name: Orgado – Organic Food & Grocery eCommerce Rract NextJs Template
    Author: Bdevs
    Support: https://www.devsnews.com/support/
    Description: Orgado – Organic Food & Grocery eCommerce HTML5 Template
    Version: 1.0
	Developer: Hasibul Hossen

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. Theme default css start
  	02. Animation css start
	03. Background color css start
	04. Breadcrumb css start
	05. Button css start
	06. Carousel css start
	07. Offcanvas css start
	08. Preloder css start
	09. Section title css start
	10. About css start
	11. Accordiong css start
	12. Banner css start
	13.	Brand css start
	14.	Category css start
	15.	Choose css start
	16.	Contact css start
	17.	Counter css start
	18.	Error css start
	19.	Features css start
	20.	Footer css start
	21.	Gallery css start
	22.	Header css start
	23.	Login css start
	24.	Google map css start
	25.	Mean menu css start
	26.	News css start 
	27.	Pgage banner css start
	28.	Product details css start
	29.	Product css start
	30.	Shop css start
	31. Step css start
	32. Subcribe css start
	33. Team css start
	34. Testimonial css start
	35. Trending css start
**********************************************/




/*----------------------------------------*/
/*  01. Theme default css start
/*----------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');


*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/*---------------------------------
    typography css start 
---------------------------------*/
body {
	font-family: var(--bd-ff-body);
	font-size: var(--bd-fs-body);
	font-weight: normal;
	color: var(--clr-common-body-text);
	line-height: 26px;
}
body[dir="rtl"] {
	font-family: 'Almarai', sans-serif;
}
a{
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--bd-ff-heading);
	color: var(--clr-common-heading);
	margin-top: 0px;
	font-weight: var(--bd-fw-bold);
	line-height: 1.3;
	@include transition(.3s);
}

[dir="rtl"] h1,
[dir="rtl"] h2,
[dir="rtl"] h3,
[dir="rtl"] h4,
[dir="rtl"] h5,
[dir="rtl"] h6  {
	font-family: 'Almarai', sans-serif;
}

h1 {
	font-size: var(--bd-fs-h1);
}
  
h2 {
font-size: var(--bd-fs-h2);
}

h3 {
font-size: var(--bd-fs-h3);;
}

h4 {
font-size: var(--bd-fs-h4);
font-weight: var(--bd-fw-sbold);
}

h5 {
font-size: var(--bd-fs-h5);
}

h6 {
font-size: var(--bd-fs-h6);
}

ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none;
}
p {
	font-family: var(--bd-ff-p);
	font-size: var(--bd-fs-p);
	font-weight: var(--bd-fw-normal);
	color: var(--clr-common-body-text);
	margin-bottom: 15px;
	line-height: 26px;
}

[dir="rtl"] p {
	font-family: 'Almarai', sans-serif;
}

a,
.btn,
button,
span,
p,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover
{
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}
button:hover{
	cursor: pointer;
}

button:focus{
    outline: 0; 
    border:0
}

.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

input{
	outline: none;
}

input[type="color"] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
	border: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	padding: 0;
	border-radius: 50%;
}

*::-moz-selection {
	background: var(--clr-theme-1);
	color: var(--clr-common-white);
	text-shadow: none;
}
::-moz-selection {
	background: var(--clr-theme-1);
	color: var(--clr-common-white);
	text-shadow: none;
}
::selection {
	background: var(--clr-theme-1);
	color: var(--clr-common-white);
	text-shadow: none;
}

*::-moz-placeholder {
	color: var(--clr-common-body-text);
	opacity: 1;
	font-size: 16px;
}
*::placeholder {
	color: var(--clr-common-body-text);
	opacity: 1;
	font-size: 16px;
}

/*---------------------------------
    common classes css start 
---------------------------------*/

.w-img{
	& img{
		width: 100%;
	}
}

.m-img{
	& img{
		max-width: 100%;
	}
}

.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left {
	float: left
}
.f-right {
	float: right
}

.z-index-1{
	z-index: 1;
}

.z-index-11{
	z-index: 11;
}

.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}

.include-bg{
	@include background();
}

/*----------------------------------------
    Body Overlay 
-----------------------------------------*/

.body-overlay{
    background-color: rgba($color: var(--clr-common-heading), $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(.3s);

	&:hover{
		cursor: pointer;
	}
}

.body-overlay.opened{
	opacity: 1;
    visibility: visible;
}

/*----------------------------------------
    Progress Wrap
-----------------------------------------*/

.progress-wrap{
	@media #{$xs}{
		right: 15px;
		bottom: 15px;
	}
}
//Small Conatiner
@media (min-width: 1200px) {
	.container.small-container {
		max-width: 1201px;
	}
}
@media (min-width: 1400px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1320px;
	}
}
@media (min-width: 1500px) {
	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl,
	.container-xxl {
		max-width: 1440px;
	}
}

// Basic-pagaination
.bd-basic__pagination {
	ul {
		li {
			list-style: none;
			a {
				height: 45px;
				display: inline-flex;
				align-items: center;
				width: 45px;
				justify-content: center;
				border-radius: 50%;
				color: var(--clr-text-8);
				font-weight: var(--bd-fw-medium);
				border: 1px solid var(--clr-border-16);
				&:hover {
					background-color: var(--clr-theme-1);
					color: var(--clr-common-white);
					border-color: transparent;
				}
			}
			&.active{
				a {
					background: var(--clr-theme-1);
					border-color: var(--clr-theme-1);
					color: var(--clr-common-white);
				}
			}
		}
		@include flexbox();
		align-items: center;
		gap: 20px;
	}
}
