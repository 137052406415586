@use '../utils' as *;

/*----------------------------------------*/
/*  16. Contact css start
/*----------------------------------------*/

// Contact-style 
.bd-contact {
	&__main-wrapper {
		padding-right: 40px;
		@media #{$xs,$sm,$md}{
			padding-right: 0;
		}
	}
	&__list {
		@include flexbox();
		flex-direction: column;
		gap: 27px;
	}
	&__item {
		&-list {
			@include flexbox();
			gap: 20px;
		}
		.bd-contact__content {
			& > span {
				a {
					&:hover {
						color: var(--clr-theme-1);
					}
					span {
						color: var(--clr-common-heading);
						font-weight: var(--bd-fw-medium);
						&:hover {
							color: var(--clr-theme-1);
						}
					}
				}
			}
		}
	}
	&__content {
		& > span {
			display: block;
		}
		p {
			margin-bottom: 0;
		}
	}
	&__icon {
		i {
			color: var(--clr-theme-1);
		}
	}
}
.bd-single {
	&__form-input {
		input {
			width: 100%;
			height: 60px;
			background: var(--clr-bg-grey);
			border: 0;
			@include border-radius (5px);
			padding: 0 20px;
		}
		textarea {
			min-height: 170px;
			border: 0;
			background: var(--clr-bg-grey);
			padding: 15px 20px;
			resize: none;
			width: 100%;
			@include border-radius (5px);
			outline: none;
		}
	}
}
.bd-sidebar {
	&__support {
		border: 1px solid var(--clr-border-12);
		padding: 30px 30px;
		@include border-radius (10px);
		@media #{$xs,$lg}{
			padding: 30px 20px;
		}
	}
	&__title {
		h4 {
			margin-bottom: 30px;
		}
	}
}
[dir="rtl"]
{
	.bd-contact {
		&__main-wrapper {
			padding-right: 0;
			padding-left: 40px;
		}
	}
}